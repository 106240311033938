import React from "react";
import * as styles from "./GlobalPollItem.module.scss";
import {
  IGlobalPollUserVote,
  IUserVote,
} from "../../context/result/resultReducer";
import BattleBlockGlobalVotes from "../BattleBlockGlobalVotes";

const GlobalPollItem = ({
  globalPollItem,
}: {
  globalPollItem?: IGlobalPollUserVote;
}) => {
  const isWinnersExists =
    globalPollItem && globalPollItem?.poll?.winners?.length > 0;
  const isRightAnswer =
    globalPollItem?.poll.winners[0] === globalPollItem?.answer;

  return (
    <div className="globalPollItemResult">
      <BattleBlockGlobalVotes
        title={globalPollItem?.poll?.title || "No title"}
      />
      <div className={styles.globalPollItemContent}>
        <div className={styles.globalPollItemHeader}>
          {globalPollItem?.poll?.title}
        </div>
        <div
          className={`${styles.globalPollItemAnswer}   ${
            isWinnersExists &&
            (isRightAnswer ? `rightAnswer` : `incorrectAnswer`)
          }`}
        >
          {globalPollItem?.answer}
        </div>
        <div className={styles.globalPollItemFooter}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud Ut enim ad minim veniam, quis nostrud Ut enim ad
          minim veniam, quis nostrud
        </div>
      </div>
    </div>
  );
};

export default GlobalPollItem;
