import React from "react";
import * as styles from "./RatingTable.module.scss";
import anoncementIcon from "../../images/competitionTop/announcement.svg";
import btnIcon from "../../images/competitionTop/triangle.svg";
import { ratingItem } from "../ResultsBlock/ResultsBlock";

type PropsType = {
  ratingData: ratingItem[];
  actualUser: string;
  isResultPage?: boolean;
  isLast?: boolean;
  isLastItem?: boolean;
  isVisibleHeader: boolean;
};

const RatingTable: React.FC<PropsType> = ({
  ratingData,
  actualUser,
  isResultPage,
  isLastItem,
  isVisibleHeader = true,
}) => {
  const [isFull, setIsFull] = React.useState(false);

  const ratingTable = isFull ? ratingData : ratingData.slice(0, 9);
  return ratingTable.length > 0 ? (
    <div
      className={`${styles.content} ${
        isResultPage ? styles.contentWithBorderTop : " "
      } ${isLastItem ? styles.contentWithBorderTopBottom : " "}`}
    >
      <img src={anoncementIcon} className={styles.anoncementL}></img>
      {isVisibleHeader && (
        <div className={styles.tibleTitle}>Lineup prediction</div>
      )}
      <div className={styles.tableWrapp}>
        <div className="table">
          <div className="table-header">
            <div className="header__item">Positions</div>
            <div className="header__item">NICKNAME</div>
            <div className="header__item">MATCH VOTES</div>
            <div className="header__item">POINTS</div>
          </div>
          <div className="table-content">
            {ratingTable?.length &&
              ratingTable.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`table-row    ${
                      item.userId === actualUser ? "active" : " "
                    }`}
                  >
                    <div className="table-data">{i + 1}</div>
                    <div className="table-data">{item.user}</div>
                    <div className="table-data">{item.matchCount}</div>
                    <div className="table-data">{item.rating}</div>
                  </div>
                );
              })}
          </div>
        </div>
        <button className={styles.btn} onClick={() => setIsFull(!isFull)}>
          {isFull ? `Roll up` : `See full`}
          <img
            src={btnIcon}
            className={`${styles.btnImge} ${
              isFull ? styles.button_rotate : " "
            }`}
          ></img>
        </button>
      </div>
    </div>
  ) : (
    <div
      className={`${styles.noVotes} ${
        !isResultPage ? styles.noVotesWhite : " "
      }`}
    >
      no one user has points at the moment
    </div>
  );
};

export default RatingTable;
