import React, { useEffect, useState } from "react";
import * as styles from "./SetBlock.module.scss";
import useMatch from "../../../hooks/useMatch";
import Spinner from "../../Spinner";
import { IPollUserVote, IUserVote } from "../../../context/result/resultReducer";
import { getPollTypeBase, ITextFieldType } from "../../../lib/helpers";

const SetBlock = ({ matchVotes }: { matchVotes?: IUserVote }) => {
  const { nearestMatchs } = useMatch();

  if (!matchVotes?.polls?.length) {
    return;
  }

  const { textField, selectField } = getPollTypeBase(matchVotes?.polls);
  const [values, setValues] = useState<ITextFieldType[] | null>(null);
  const [option1, setOption1] = useState<[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (textField.length || selectField.length) {
      setValues(textField);
      setOption1(selectField);
    }
  }, [nearestMatchs.length]);

  return (
    <div className={styles.setBlock}>
      <form>
        <div className={styles.setBlockBody}>
          <div className={styles.bodyTop}>
            {values &&
              values.map((item, i) => {
                return (
                  <div className={styles.bodyItem} key={i}>
                    <div className={styles.bodyText}>{item.title}</div>
                    <input
                      // onChange={(e) => handleInputChange(e, item.id)}
                      className={`${styles.bodyInput} ${
                        item.checked && (item.isRightAnswer ? `rightAnswer` : `incorrectAnswer`)
                      }`}
                      type='string'
                      readOnly
                      value={item?.answer[0] || ""}
                      name={item.id}
                    />
                  </div>
                );
              })}
          </div>
          {option1.length > 0 && (
            <div className={styles.bodyMiddle}>
              {option1.map((selectedPoll, index) => (
                <div key={`${selectedPoll.pollInfo.id}_${index}`}>
                  <div className={styles.middleTitle}>{selectedPoll.pollInfo.title}</div>
                  <div className={styles.selectBlock}>
                    <div className={styles.selectItem} key={selectedPoll.pollInfo._id}>
                      <input
                        className={`${styles.bodyInput} ${
                          selectedPoll.checked &&
                          (selectedPoll.isRightAnswer ? `rightAnswer` : `incorrectAnswer`)
                        }`}
                        type='string'
                        readOnly
                        value={selectedPoll?.answer[0] || ""}
                        name={selectedPoll.pollInfo.id}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </form>
      {loading && <Spinner />}
    </div>
  );
};

export default SetBlock;
