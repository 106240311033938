// extracted by mini-css-extract-plugin
export var R = "RatingTable-module--R--RfBPu";
export var anoncementL = "RatingTable-module--anoncementL--94m-p";
export var anoncementM = "RatingTable-module--anoncementM--8M4PD";
export var btn = "RatingTable-module--btn--GRlgu";
export var btnImge = "RatingTable-module--btnImge--kYGLO";
export var button_rotate = "RatingTable-module--button_rotate--3+oT1";
export var container = "RatingTable-module--container--uEhkP";
export var content = "RatingTable-module--content--Z4Ldz";
export var contentWithBorderTop = "RatingTable-module--contentWithBorderTop--hlUy4";
export var contentWithBorderTopBottom = "RatingTable-module--contentWithBorderTopBottom--kwB+s";
export var noVotes = "RatingTable-module--noVotes--EBznJ";
export var noVotesWhite = "RatingTable-module--noVotesWhite--dlwTI";
export var resultBlock = "RatingTable-module--resultBlock--j4Iog";
export var subtitle = "RatingTable-module--subtitle--yN93l";
export var tableBlock = "RatingTable-module--tableBlock--UvZ+x";
export var tableWrapp = "RatingTable-module--tableWrapp--EE-VE";
export var tabs = "RatingTable-module--tabs--ZwNS3";
export var tabsItem = "RatingTable-module--tabsItem--uMECT";
export var tibleTitle = "RatingTable-module--tibleTitle--CMZX3";
export var title = "RatingTable-module--title--a30Um";