// extracted by mini-css-extract-plugin
export var accordionItem = "GlobalPollItem-module--accordionItem--SwDQl";
export var accordionItemWhite = "GlobalPollItem-module--accordionItemWhite--VSIxZ";
export var animated = "GlobalPollItem-module--animated--Qc+JR";
export var content = "GlobalPollItem-module--content--062m5";
export var globalPollItemAnswer = "GlobalPollItem-module--globalPollItemAnswer--u6adY";
export var globalPollItemContent = "GlobalPollItem-module--globalPollItemContent--wQBAE";
export var globalPollItemFooter = "GlobalPollItem-module--globalPollItemFooter--FS56i";
export var globalPollItemHeader = "GlobalPollItem-module--globalPollItemHeader--yhxz5";
export var header = "GlobalPollItem-module--header--crlRG";
export var icon = "GlobalPollItem-module--icon--tbu5i";
export var iconRatingPage = "GlobalPollItem-module--iconRatingPage--XUcfk";
export var iconTimer = "GlobalPollItem-module--iconTimer--RgF0o";
export var rotate = "GlobalPollItem-module--rotate--LEjaq";
export var title = "GlobalPollItem-module--title--7cX2R";
export var titleBlack = "GlobalPollItem-module--titleBlack--QBg8U";