import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./BattleBlockGlobalVotes.module.scss";
import iconLiverpool from "./../../../images/competitionTop/iconLIverpool.svg";
import { IGlobalPoll, IUserVote } from "../../context/result/resultReducer";

const BattleBlockGlobalVotes = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div>
      <div className={styles.battle}>
        <div className={styles.battleRow}>{title}</div>
        {description && <div className={styles.subTitle}>{description}</div>}
      </div>
    </div>
  );
};

export default BattleBlockGlobalVotes;
