// extracted by mini-css-extract-plugin
export var container = "StarRatingVotes-module--container--oiK2j";
export var iconBlock = "StarRatingVotes-module--iconBlock--V5Iw7";
export var iconBlockSelect = "StarRatingVotes-module--iconBlockSelect--ebAnO";
export var notVotes = "StarRatingVotes-module--notVotes--slpGy";
export var rating = "StarRatingVotes-module--rating--oSm+v";
export var ratingWrapper = "StarRatingVotes-module--ratingWrapper--FTSik";
export var starItem = "StarRatingVotes-module--starItem--ky5Xt";
export var starRow = "StarRatingVotes-module--starRow--ahRLc";
export var startRating = "StarRatingVotes-module--startRating--PTVsH";
export var text = "StarRatingVotes-module--text--99gvC";
export var title = "StarRatingVotes-module--title--1kEAf";