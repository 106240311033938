import React from "react";
import * as styles from "./ButtleBlock.module.scss";
import iconLiverpool from "./../../../images/competitionTop/iconLIverpool.svg";
import { IUserVote } from "../../../context/result/resultReducer";

const ButtleBlock = ({ matchVotes }: { matchVotes?: IUserVote }) => {

  const match = matchVotes?.matchInfo
  if (!match) {
    return null;
  }

  const scoreLiverpool = match.finishOptions.matchScore.liverpool;
  const scoreOpponent = match.finishOptions.matchScore.opponent;

  const logoApponent = match?.opponent?.logo;
  const apponentName = match?.opponent?.name;
  const isHomeMatch = match?.isHomeMatch;
  const eventName = match?.eventName;

  const dateMatch = new Date(match?.matchDate);
  // const hours = dateMatch.getUTCHours() + 1;
  const hours = dateMatch.getUTCHours();
  const minutes = dateMatch.getMinutes();


  return (
    <div>
      <div className={styles.battle}>
        <div className={styles.battleRow}>
          <div className={styles.battleBlock}>
            <img
              placeholder="blurred"
              className={styles.battleImage}
              src={isHomeMatch ? iconLiverpool : logoApponent}
              alt="image commands"
            />

            <div className={styles.ButtleContent}>
              {isHomeMatch ? `Liverpool` : apponentName}
            </div>
          </div>
          <div className={styles.battleBlock}>
            {match.isFinished ? (
              <div className={styles.score}>
                {isHomeMatch ? scoreLiverpool : scoreOpponent} -{" "}
                {isHomeMatch ? scoreOpponent : scoreLiverpool}
              </div>
            ) : (
              <div className={styles.ButtleContent}>V</div>
            )}
          </div>
          <div className={styles.battleBlock}>
            <img
              placeholder="blurred"
              className={styles.battleImage}
              src={isHomeMatch ? logoApponent : iconLiverpool}
              alt="image commands"
            />
            <div className={styles.ButtleContent}>
              {!isHomeMatch ? `Liverpool` : apponentName}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttleTime}>
        <p className={styles.timetext}>
          <span className={styles.red}>
            {dateMatch.toDateString()},
          </span>{" "}
          Kick Off:{" "}
          <span className={styles.red}>
            {hours}:{minutes}
          </span>{" "}
          (UK Time)
        </p>
        <p className={styles.timetext}>
          {eventName}
          {", "}
          <span className={styles.black}>
            {match.isFinished ? "Match stats" : "Match preview"}{" "}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ButtleBlock;
