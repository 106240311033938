import React, { useState } from "react";
import * as styles from "./AccordionResultItem.module.scss";
import icon from "../../images/about/tringularFAQ.svg";
import timerIcon from "../../images/results/timer.svg";
import MyMatchVotes from "../MyMatchVotes";
import { IUserVote } from "../../context/result/resultReducer";

const AccordionResultItem = ({
  title,
  matchVotes,
  children,
  isResultPage,
  isRating,
  isLastItem,
  isFirstItem,
  id,
}: {
  title: string;
  matchVotes?: IUserVote;
  children?: any;
  isResultPage?: boolean;
  isLastItem?: boolean;
  isFirstItem?: boolean;
  isRating?: boolean;
  id: string;
}) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div
      id={id}
      className={
        isResultPage ? styles.accordionItemWhite : styles.accordionItem
      }
    >
      <div
        className={`${!isRating ? styles.header : styles.headerWithBorderTop} ${
          isLastItem ? "withoutBorder" : " "
        }`}
        onClick={() => setIsShow(!isShow)}
      >
        {!isResultPage && (
          <img className={styles.iconTimer} src={timerIcon} alt="timericon" />
        )}
        <div
          className={`${!isResultPage ? styles.title : styles.titleBlack} ${
            isFirstItem ? "firstItem60" : " "
          }`}
        >
          {title}
        </div>
        <img
          className={`${!isResultPage ? styles.icon : styles.iconRatingPage} ${
            isShow ? styles.rotate : ""
          } ${isFirstItem ? styles.iconRatingPageFirstItem : ""}`}
          src={icon}
          alt="triangular"
        />
      </div>
      <div className={`${styles.content} ${isShow ? styles.animated : ""} `}>
        {matchVotes && <MyMatchVotes matchVotes={matchVotes} />}
        {children}
      </div>
    </div>
  );
};

export default AccordionResultItem;
