// extracted by mini-css-extract-plugin
export var ButtleContent = "BattleBlockGlobalVotes-module--ButtleContent--4N37p";
export var battle = "BattleBlockGlobalVotes-module--battle--bmbsv";
export var battleBlock = "BattleBlockGlobalVotes-module--battleBlock--BoSHu";
export var battleImage = "BattleBlockGlobalVotes-module--battleImage--d2WCK";
export var battleRow = "BattleBlockGlobalVotes-module--battleRow--cDNEr";
export var black = "BattleBlockGlobalVotes-module--black--IqmiD";
export var buttleTime = "BattleBlockGlobalVotes-module--buttleTime--XsOy0";
export var red = "BattleBlockGlobalVotes-module--red--IBZpG";
export var score = "BattleBlockGlobalVotes-module--score--6qgHe";
export var subTitle = "BattleBlockGlobalVotes-module--subTitle--vV8vy";
export var timetext = "BattleBlockGlobalVotes-module--timetext--jQlCd";