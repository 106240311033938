import React from "react";

const CustomCheackBox = ({
  name,
  checked,
  type,
  id,
}: {
  name: string;
  type: string;
  checked?: boolean;
  id: string | undefined;
}) => {
  let styleName = ''
  switch (type) {
    case "incorrect":
      styleName = "incorrectCheckBox"
    break;
    case "correct":
      styleName = "correctCheckBox"
    break;
    case "rightAnswer":
      styleName = "rightAnswerCheckbox"
    break;
    case "voted":
      styleName = "votedCheckbox"
    default:
      break;
  }

  return (
    <div>
      <input
        type="checkbox"
        className={`custom-checkbox ${styleName}
        `}
        id={id}
        readOnly
        name={name}
        value={name}
        checked={checked}
      />
      <label htmlFor={id}>
        <span
          className={`checkbox_wrapper ${
            type === "incorrect" ? "lineThrough" : " "
          }`}
        >
          {name}
        </span>
      </label>
    </div>
  );
};

export default CustomCheackBox;
