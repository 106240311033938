import React, { useEffect, useRef, useState } from "react";
import { Rating } from "react-simple-star-rating";
import * as styles from "./StarRatingVotes.module.scss";
import Spinner from "../../Spinner";
import { IUserVote } from "../../../context/result/resultReducer";

const getPollCommunits = (matchVotes: IUserVote) => {
  if (!matchVotes) {
    return { starFiled: [], pollId: undefined };
  }

  const pollCommunity = matchVotes.polls.find((item) => item.pollInfo.typePoll === "community");
  if (!pollCommunity) {
    return { starFiled: [], pollId: undefined };
  }

  const starFiled = pollCommunity.communityAnswer.map((item: any) => {
    return { footballer: item.footballer, starValues: item.stars };
  });

  return { starFiled };
};

const StarRating = ({ matchVotes }: { matchVotes?: IUserVote }) => {
  const [state, setState] = useState<[]>([]);
  const pollIdRef = useRef<string | undefined>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!matchVotes) {
      return;
    }
    const { starFiled, pollId } = getPollCommunits(matchVotes);

    if (!starFiled) {
      return;
    }

    pollIdRef.current = pollId;

    if (starFiled.length) {
      //@ts-ignore
      setState(starFiled);
    }
  }, []);

  const mapRatingToNum = (n: number) => {
    const res = n / 20;
    return String(res);
  };

  return (
    state.length > 0 && (
      <div className={styles.startRating}>
        <form className={styles.container}>
          <h3 className={styles.title}>Rate the players</h3>
          <div className={styles.starRow}>
            {state.map((item: { footballer: string; starValues: number }, i) => {
              return (
                <div className={styles.starItem} key={i}>
                  <div className={styles.text}>{item.footballer}</div>
                  <div className={styles.ratingWrapper}>
                    <div className={styles.iconBlockSelect}>
                      <span>{item.starValues}</span>
                    </div>

                    <Rating
                      // className={styles.rating}
                      allowHover={false}
                      readonly
                      fillColor='#FF4D4D'
                      ratingValue={Number(item.starValues) * 20}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </form>
        {loading && <Spinner />}
      </div>
    )
  );
};

export default StarRating;
