import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { IUserVote } from "../../context/result/resultReducer";
import ButtleBlock from "./BattleBlockVotes";
import CheckboxBlock from "./CheckboxBlockVotes/CheckboxBlock";
import * as styles from "./MyMatchVotes.module.scss";
import SetBlock from "./SetBlockVotes";
import StarRating from "./StarRatingVotes";

const MyMatchVotes = ({ matchVotes }: { matchVotes?: IUserVote }) => {
  return (
    <section className={styles.NextLeverpool}>
      <div className={styles.container}>
        <>
          <ButtleBlock matchVotes={matchVotes} />
          <SetBlock matchVotes={matchVotes} />
          <CheckboxBlock matchVotes={matchVotes} />
          <StarRating matchVotes={matchVotes} />
        </>
      </div>
    </section>
  );
};

export default MyMatchVotes;
