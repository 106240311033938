// extracted by mini-css-extract-plugin
export var bodyFooter = "SetBlock-module--bodyFooter--sY07C";
export var bodyInput = "SetBlock-module--bodyInput--R2OfY";
export var bodyItem = "SetBlock-module--bodyItem--GtfV+";
export var bodyMiddle = "SetBlock-module--bodyMiddle--9GjUp";
export var bodyText = "SetBlock-module--bodyText--OjlvV";
export var bodyTop = "SetBlock-module--bodyTop--cGqPw";
export var empty = "SetBlock-module--empty--MvIe-";
export var middleTitle = "SetBlock-module--middleTitle--n3LSD";
export var rightBlock = "SetBlock-module--rightBlock--On1iK";
export var select = "SetBlock-module--select--wNIca";
export var selectBlock = "SetBlock-module--selectBlock--KfYam";
export var selectItem = "SetBlock-module--selectItem--fI4Vi";
export var setBlock = "SetBlock-module--setBlock--Pzc2a";
export var setBlockBody = "SetBlock-module--setBlockBody--lRCVI";
export var submit = "SetBlock-module--submit--eRqBp";