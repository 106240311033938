// extracted by mini-css-extract-plugin
export var accordionItem = "AccordionResultItem-module--accordionItem--RdE-r";
export var accordionItemWhite = "AccordionResultItem-module--accordionItemWhite--a0t1w";
export var animated = "AccordionResultItem-module--animated--8vTMC";
export var content = "AccordionResultItem-module--content--zGZJL";
export var header = "AccordionResultItem-module--header---wZI0";
export var headerWithBorderTop = "AccordionResultItem-module--headerWithBorderTop--vUYiI";
export var icon = "AccordionResultItem-module--icon--J4NsQ";
export var iconRatingPage = "AccordionResultItem-module--iconRatingPage--fz06b";
export var iconRatingPageFirstItem = "AccordionResultItem-module--iconRatingPageFirstItem--5ujks";
export var iconTimer = "AccordionResultItem-module--iconTimer--r+vPo";
export var rotate = "AccordionResultItem-module--rotate--duKTF";
export var title = "AccordionResultItem-module--title--6Tdsm";
export var titleBlack = "AccordionResultItem-module--titleBlack--qMi7b";