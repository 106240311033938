import React, { useEffect, useState } from "react";
import {
  IPollUserVote,
  IUserVote,
} from "../../../context/result/resultReducer";
import Spinner from "../../Spinner";
import * as styles from "./CheckboxBlock.module.scss";
import CustomCheackBox from "./CustomCheackBox";

const CheckboxBlock = ({ matchVotes }: { matchVotes?: IUserVote }) => {
  // const players = matchVotes?.matchInfo.polls[0].poll.options
  // const pollId = matchVotes?.matchInfo.polls[0].poll._id;

  const lineUpPoll = matchVotes?.polls.find(
    (poll: IPollUserVote) => poll.pollInfo.typePoll === "lineUp"
  );

  const [state, setState] = useState(lineUpPoll?.pollInfo.options);
  const [loading, setLoading] = useState(false);

  const answer = lineUpPoll?.answer;
  const rightAnswers = lineUpPoll?.rightAnswers;

  return lineUpPoll ? (
    <div className={styles.checkboxBlock}>
      <div className={styles.container}>
        <h3 className={styles.title}>Guess the lineup for the game</h3>
        <div className={styles.row}>
          {state &&
            state.map((item, i) => {
              let selectType = "";
              if (!rightAnswers?.length && answer?.includes(item.title)) {
                selectType = "voted";
              } else if (!rightAnswers?.length) {
                selectType = "noRightAnswers";
              } else if (
                answer?.includes(item.title) &&
                rightAnswers?.includes(item.title)
              ) {
                selectType = "correct";
              } else if (
                answer?.includes(item.title) &&
                !rightAnswers?.includes(item.title)
              ) {
                selectType = "incorrect";
              } else if (
                !answer?.includes(item.title) &&
                rightAnswers?.includes(item.title)
              ) {
                selectType = "rightAnswer";
              }

              return (
                <div className={styles.chackBoxItem} key={i}>
                  <CustomCheackBox
                    id={item._id}
                    type={selectType}
                    checked={false}
                    name={item.title}
                  />
                </div>
              );
            })}
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  ) : (
    ""
  );
};

export default CheckboxBlock;
