import * as React from "react";
import * as styles from "../styles/Profile.module.scss";
import Layout from "../component/Layout";
import { Helmet } from "react-helmet-async";
import AccordionResultItem from "../component/AccordionResultItem/AccordionResultItem";
import iconTrophy from "../images/results/trophy.svg";
import iconTrophyM from "../images/results/iconTrophyM.svg";
import iconAnnoncer from "../images/results/announcement.svg";
import iconAnnoncerM from "../images/results/iconAnnoncerM.svg";
import { useEffect } from "react";
import useResults from "../hooks/use-results";
import { AuthContext } from "../context/auth/authContext";
import RatingTable from "../component/RatingTable";
import GlobalPollItem from "../component/GlobalPollItem/GlobalPollItem";
import { navigate } from "gatsby";
import useProfile from "../hooks/use-profile";
import { NoNFT } from "../component/NFTList";
import HeaderPage from "../component/Profile/HeaderPage";
import useWindowDimensions from "../lib/useDemenstion";
import Spinner from "../component/Spinner";

const MyVotes = () => {
  const {
    getUserVotes,
    userVotes,
    getAllSeasonRating,
    statRating,
    lineUpRating,
    globalUserVotes,
    getGlobalUserVotes,
    top1PlayerRating,
    isLoading,
  } = useResults();
  const { isAuthentificated, userId } = React.useContext(AuthContext);

  const isReadyPage = React.useRef(false);
  const { width } = useWindowDimensions();
  const { isSeasonTokenExists, isLoading: isLoadingToken } = useProfile();
  const accordionItems = [
    {
      indexCategory: 0,
      title: "LINEUP PREDICTION",
    },
    {
      indexCategory: 1,
      title: "PLAYER OF THE MATCH PREDICTION",
    },
    {
      indexCategory: 2,
      title: "STATS PREDICTION",
    },
  ];

  // useEffect(() => {
  //   if (!isAuthentificated) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (isAuthentificated) {
      getUserVotes();
      getAllSeasonRating();
      getGlobalUserVotes();
    }
  }, [isAuthentificated]);

  const selectRatingData = (activeCategory: number) => {
    switch (activeCategory) {
      case 0:
        return lineUpRating;
      case 1:
        return top1PlayerRating;
      case 2:
        return statRating;
      default:
        return [];
    }
  };

  useEffect(() => {
    isReadyPage.current = true;
  }, []);

  return (
    <>
      <Helmet>
        <title> My Votes - Reds Club</title>
        <meta property='og:title' content='My Votes  - Reds Club' />
        <meta name='description' content='My Votes ' />
        <meta property='og:description' content='My Votes' />
      </Helmet>
      <Layout>
        <main
          style={{ backgroundColor: isSeasonTokenExists ? "#b22424" : "#edebed" }}
          className={styles.container}
        >
          <section className={!isSeasonTokenExists ? styles.myVotes : ""}>
            {isSeasonTokenExists ? (
              <>
                <section className={styles.resultBlock}>
                  <div className={styles.iconTrophy}>
                    {width < 768 ? <img src={iconTrophyM} /> : <img src={iconTrophy} />}
                  </div>
                  <div className={styles.iconAnnoncer}>
                    {width < 768 ? <img src={iconAnnoncerM} /> : <img src={iconAnnoncer} />}
                  </div>
                  <div>
                    <div className={styles.headerResult}>Results</div>
                    <h2 className={styles.subTitle}>
                      During each football season we run competitions related to the current
                      Liverpool games. Thus, before each game, Reds Club members can predict several
                      statistical events, and those who guess the most during the season will
                      receive rare collectible NFTs. Also, after each game, members can evaluate the
                      actions of Liverpool players, and at the end of the season we will choose the
                      best and award them.
                    </h2>
                    <div className={styles.accordionSection}>
                      {accordionItems.length > 0 &&
                        accordionItems.map((item, i) => {
                          return (
                            <AccordionResultItem
                              id={String(item.indexCategory)}
                              title={item.title}
                              isResultPage={true}
                              isRating={true}
                              isLastItem={accordionItems.length === i + 1}
                              isFirstItem={i === 0}
                              children={
                                <RatingTable
                                  isResultPage={true}
                                  isLastItem={accordionItems.length === i + 1}
                                  ratingData={selectRatingData(item.indexCategory)}
                                  actualUser={userId}
                                  isVisibleHeader={false}
                                />
                              }
                              key={i}
                            />
                          );
                        })}
                    </div>
                  </div>
                </section>
                <section className={styles.myVotesBlock}>
                  <div className={styles.myVotesWrapp}>
                    <div className={styles.myVotesTitle}>YOUR voting history</div>

                    <div className={styles.accordionSection}>
                      <div className={styles.accordionItems}>
                        {!!userVotes.length &&
                          userVotes.map((item, i) => {
                            return (
                              <AccordionResultItem
                                id={item.matchInfo.eventName}
                                title={item.matchInfo.eventName}
                                matchVotes={item}
                                key={i}
                              />
                            );
                          })}
                      </div>

                      <>
                        {!!globalUserVotes.length &&
                          globalUserVotes.map((item, i) => {
                            return (
                              <AccordionResultItem
                                id={String(item.poll.id)}
                                title={item.poll.title}
                                children={<GlobalPollItem globalPollItem={item} />}
                                key={i}
                              />
                            );
                          })}
                      </>

                      <>
                        {!globalUserVotes.length && !userVotes.length && (
                          <div className={styles.noVotes}>no votes</div>
                        )}
                      </>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <div>
                <HeaderPage title='my votes' />
                {isLoadingToken && <Spinner />}
                {!isLoadingToken && isReadyPage.current && (
                  <NoNFT title='Here you can find your voting history when you get membership NFT and start voting with it' />
                )}
              </div>
              // "Here you can find your voting history when you get membership NFT and start voting with it"
            )}
          </section>
        </main>
      </Layout>
    </>
  );
};

export default MyVotes;
