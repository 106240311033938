// extracted by mini-css-extract-plugin
export var ButtleContent = "ButtleBlock-module--ButtleContent--abd7-";
export var battle = "ButtleBlock-module--battle--xLubw";
export var battleBlock = "ButtleBlock-module--battleBlock--QmAcl";
export var battleImage = "ButtleBlock-module--battleImage--G3pfZ";
export var battleRow = "ButtleBlock-module--battleRow--3AXJy";
export var black = "ButtleBlock-module--black--qjwCB";
export var buttleTime = "ButtleBlock-module--buttleTime--Ir+pG";
export var red = "ButtleBlock-module--red--ATia1";
export var score = "ButtleBlock-module--score--+lMhu";
export var timetext = "ButtleBlock-module--timetext--3finy";